import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from '../../../app.service';
import { Auth } from '../../../decorators/auth.decorator';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { IsLoggedIn } from '../../../decorators/is-logged-in.decorator';
import { IsModalOpen } from '../../../decorators/is-modal-open.decorator';
import { IsSidenavMouseOver } from '../../../decorators/is-sidenav-mouse-over.decorator';
import { CityWithChild } from '../../../domains/city-with-child';
import { CountryWithChild } from '../../../domains/country-with-child';
import { CountyWithChild } from '../../../domains/county-with-child';
import { LocationEmbed } from '../../../domains/location-embed';
import { Restaurant } from '../../../domains/restaurant';
import { RestaurantExplore } from '../../../domains/restaurant-explore';
import { RestaurantSimple } from '../../../domains/restaurant-simple';
import { StateWithChild } from '../../../domains/state-with-child';
import { User } from '../../../domains/user';
import { BooleanLocalStorage } from '../../../local-storage/boolean-local-storage';
import { LocalStorage } from '../../../local-storage/local-storage';
import { NumberLocalStorage } from '../../../local-storage/number-local-storage';
import { LocalStorageKey } from '../../../models/enums/local-storage-key';
import { ModalCloseReasons } from '../../../models/enums/modal-close-reasons';
import { ModalType } from '../../../models/enums/modal-type';
import { SearchResultSlideType } from '../../../models/enums/search-result-slide-type';
import { SidenavType } from '../../../models/enums/sidenav-type';
import { TourType } from '../../../models/enums/tour-type';
import { LocationFavorites } from '../../../models/location-favorites';
import { Modal } from '../../../models/modal';
import { Place } from '../../../models/place';
import { SearchParams } from '../../../models/search-params';
import { SearchResult } from '../../../models/search-result';
import { SearchResultSlide } from '../../../models/search-result-slide';
import { TypeCategorySection } from '../../../models/type-category-section';
import { AuthenticationService } from '../../../services/authentication.service';
import { EmbedService } from '../../../services/embed.service';
import { FavoritesService } from '../../../services/favorites.service';
import { ModalService } from '../../../services/modal.service';
import { NavigatorService } from '../../../services/navigator.service';
import { SearchParamsService } from '../../../services/search-params.service';
import { ShareService } from '../../../services/share.service';
import { DomUtils } from '../../../utils/dom-utils';

@Component({
  selector: 'app-search-desktop',
  templateUrl: './search-desktop.component.html',
  styleUrls: [
    './search-desktop.component.scss',
    '../../../../vendor/libs/ng-select/ng-select.scss',
    'search-desktop-loader/search-desktop-loader.component.scss'
  ]
})
export class SearchDesktopComponent implements OnInit, OnChanges, OnDestroy {
  @Input() country: CountryWithChild;
  @Input() searchResult: SearchResult;
  @Input() searchParams: SearchParams;
  @Input() stateKey: string;
  @Input() cityName: string;
  @Input() isSearching: boolean;

  @Output() search = new EventEmitter<boolean>();

  @ViewChild('exploreEmpty') exploreEmpty: ElementRef;
  @ViewChild('restaurantAndChainsAndDividerModalRef') restaurantAndChainsAndDividerModalRef: ElementRef;
  @ViewChild('favoriteDesktopModalRef') favoriteDesktopModalRef: ElementRef;
  @ViewChild('addRestaurantModalRef') addRestaurantModalRef: ElementRef;
  @ViewChild('videoModalRef') videoModalRef: ElementRef;

  @CurrentUser() currentUser: User;

  readonly restaurantTitleHeight = 66;
  readonly marginFromPreviousSectionsLastFood = 94;

  isCitySelected = true;
  sectionActive: TypeCategorySection;
  hideExploreButton = false;
  clickedRestaurant: RestaurantExplore;
  explorePreviewLocalStorage = new BooleanLocalStorage(LocalStorageKey.EXPLORE_PREVIEW);
  restaurantPositionLocalStorage = new NumberLocalStorage(LocalStorageKey.RESTAURANT_POSITION);
  preview = false;
  locationFavorites: LocationFavorites;
  favoriteClickSubjectSubscription: Subscription;
  hasNext = false;
  hasPrev = false;
  searchValueExists = false;
  stateSelected: StateWithChild;
  countySelected: CountyWithChild;

  @IsModalOpen(ModalType.LOGIN) isLoginModalOpen: boolean;
  @IsModalOpen(ModalType.SUBSCRIBE_DESKTOP) isSubscribeModalOpen: boolean;
  @IsModalOpen(ModalType.RESTAURANT_SQUARE) isRestaurantModalOpen: boolean;
  @IsModalOpen(ModalType.SEARCH_SQUARE) isSearchModalOpen: boolean;
  @IsModalOpen(ModalType.FAVORITE_SQUARE) isFavoriteModalOpen: boolean;
  @IsModalOpen(ModalType.VIDEO_DESKTOP) isVideoModalOpen: boolean;

  @IsLoggedIn() isLoggedIn: boolean;

  @IsSidenavMouseOver([SidenavType.LEFT, SidenavType.RIGHT]) isSidenavLeftOrRightMouseOver: boolean;
  @IsSidenavMouseOver([SidenavType.RIGHT]) isSidenavRightMouseOver: boolean;

  scrollTopPosition = 0;
  restaurantAndChainsAndDividerModal: Modal;
  isLocalitySelected: boolean;
  activeSearchResultSlide: SearchResultSlide;
  searchResultSlideType = SearchResultSlideType;
  activeTypeCategorySection: TypeCategorySection;
  restaurantId: number;
  searchParamsLocalStorage = new LocalStorage(SearchParams, LocalStorageKey.SEARCH_PARAMS);
  locationEmbeds: Array<LocationEmbed>;
  hasWalkingEmbeds = false;
  hasDiningEmbeds = false;
  TourType = TourType;

  constructor(
    private appService: AppService,
    private navigatorService: NavigatorService,
    private shareService: ShareService,
    private modalService: ModalService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private favoritesService: FavoritesService,
    private searchParamsService: SearchParamsService,
    private embedService: EmbedService,
    @Inject(DOCUMENT) private _document: Document
  ) {
  }

  ngOnInit(): void {
    this.embedService.getAllLocationEmbeds(this.searchParams.locationId, this.searchParams.locationType)
      .subscribe((embeds) => {
        this.locationEmbeds = embeds;

        this.checkEmbedExistence();
      });

    this.favoriteClickSubjectSubscription = this.favoritesService.favoriteClickSubject.subscribe(value => {
      if (value) {
        this.modalService.open(this.favoriteDesktopModalRef, ModalType.FAVORITE_SQUARE);

        this.favoritesService.favoriteClickSubject.next(false);
      }
    });

    this.preview = this.explorePreviewLocalStorage.getItem();

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.restaurantId) {
        this.restaurantId = parseInt(params.restaurantId, 10);
      }
    });

    this.scrollToPrevPosition();

    const restaurantPosition = this.restaurantPositionLocalStorage.getItem();

    if (restaurantPosition) {
      DomUtils.querySelectorAsync('.search-desktop-category').then(() => {
        setTimeout(() => {
          this.appService.scrollTop(restaurantPosition, 0);

          this.restaurantPositionLocalStorage.removeItem();
        });
      });
    }

    this.getLocalityFavorites();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('searchResult') && this.searchResult.isReady && this.restaurantId) {
      setTimeout(() => {
        const restaurantElement = document.getElementById('restaurant-' + this.restaurantId);

        if (restaurantElement) {
          restaurantElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
        }
      }, 100);
    }

    this.getLocalityFavorites();
  }

  ngOnDestroy(): void {
    this.favoriteClickSubjectSubscription.unsubscribe();
  }

  onStateChange(event) {
    this.stateSelected = event;
  }

  onCountyChange(event) {
    this.countySelected = event;
  }

  getLocalityFavorites() {
    if (this.searchResult.groups !== undefined && this.searchResult.exampleRestaurant) {
      this.favoritesService.getFavoritesBySearchParams(this.searchParams)
        .then((locationFavorites: LocationFavorites) => {
          this.locationFavorites = locationFavorites;
        });
    }
  }

  @HostListener('window:scroll')
  onScroll() {
    const typeCategorySectionsElements = document.querySelectorAll('.search-desktop-category-section');

    const visibleTypeCategorySectionsElements = Array.prototype.filter.call(typeCategorySectionsElements,
      (node: HTMLElement) => {
        const viewportOffset = node.getBoundingClientRect();

        return viewportOffset.bottom > 0 && viewportOffset.top < window.innerHeight;
      }
    );

    visibleTypeCategorySectionsElements.forEach((typeCategorySectionElement: HTMLElement) => {
      const rect = typeCategorySectionElement.getBoundingClientRect();

      if (
        (!this.sectionActive && rect.top <= (window.innerHeight - this.restaurantTitleHeight)) ||
        rect.top < this.marginFromPreviousSectionsLastFood
      ) {
        const dataKeys = typeCategorySectionElement.dataset.keys;

        this.searchResult.groups?.forEach(groups => {
          const typeCategorySection =
            groups.sections.find(typeCategorySectionI => typeCategorySectionI.keysString === dataKeys);

          if (typeCategorySection !== undefined) {
            this.sectionActive = typeCategorySection;
          }
        });
      }
    });

    if (this.exploreEmpty) {
      let exploreEmptyRect = this.exploreEmpty.nativeElement.getBoundingClientRect();

      if (exploreEmptyRect.top === 0) {
        this.sectionActive = null;
      } else if (!this.sectionActive) {
        this.sectionActive = this.searchResult.groups?.length > 0 ? this.searchResult.groups[0][0] : null;
      }

      exploreEmptyRect = this.exploreEmpty?.nativeElement.getBoundingClientRect();

      this.hideExploreButton = exploreEmptyRect?.top < 0;
    }
  }

  typeCategorySectionSelect(typeCategorySection: TypeCategorySection) {
    this.sectionActive = typeCategorySection;

    setTimeout(() => {
      const typeCategorySectionElement = document.querySelector(
        `#search-desktop-category-section-${typeCategorySection.keysString}`
      );

      if (typeCategorySectionElement) {
        typeCategorySectionElement.scrollIntoView({
          behavior: 'auto',
          block: 'start',
          inline: 'nearest'
        });

        setTimeout(() => {
          const offsetRem = 4.5 * parseFloat(getComputedStyle(document.documentElement).fontSize);
          window.scrollBy({ top: -offsetRem, left: 0, behavior: 'auto' });
        }, 700);
      }
    });
  }

  openRestaurantModal(restaurant: RestaurantExplore, typeCategorySection: TypeCategorySection) {
    this.activeTypeCategorySection = typeCategorySection;

    this.clickedRestaurant = restaurant;
    this.activeSearchResultSlide = this.searchResult.getSlideByRestaurant(restaurant);

    this.hasPrevHasNext();

    this.openRestaurantAndChainsAndDividerModal();
  }

  openChainDesktopModal(typeCategorySection: TypeCategorySection) {
    this.activeTypeCategorySection = typeCategorySection;

    this.activeSearchResultSlide = this.searchResult.getSlideByChains(typeCategorySection);

    this.hasPrevHasNext();

    if (typeCategorySection.hasChain) {
      this.openDividerModal(typeCategorySection);
    }
  }

  openDividerModal(typeCategorySection: TypeCategorySection = null) {
    this.activeTypeCategorySection = typeCategorySection;
    this.activeSearchResultSlide = this.searchResult.getSlideByDivider(typeCategorySection);

    this.hasPrevHasNext();

    this.openRestaurantAndChainsAndDividerModal();
  }

  hasPrevHasNext() {
    this.hasPrev = !!this.searchResult.getPreviousSlide(this.activeSearchResultSlide);
    this.hasNext = !!this.searchResult.getNextSlide(this.activeSearchResultSlide);
  }

  openRestaurantAndChainsAndDividerModal() {
    this.scrollTopPosition = this._document.documentElement.scrollTop;

    this.restaurantPositionLocalStorage.setItem(this.scrollTopPosition);

    this.restaurantAndChainsAndDividerModal = this.modalService.open(
      this.restaurantAndChainsAndDividerModalRef,
      ModalType.RESTAURANT_SQUARE
    );

    this.restaurantAndChainsAndDividerModal.onClose().then((reason) => {
      if (reason !== ModalCloseReasons.NEXT_NAVIGATE) {
        this.scrollToPrevPosition();
      }
    });
  }

  goToRestaurant(restaurant: RestaurantSimple | Restaurant) {
    this.navigatorService.goToRestaurantWithObject(restaurant);
  }

  shareMMMM(social: string = null) {
    this.shareService.shareMMMM(social);
  }

  addRestaurantDesktop() {
    this.modalService.open(this.addRestaurantModalRef, ModalType.RESTAURANT_ADD_DESKTOP);
  }

  onCitySelected(citySelected: CityWithChild) {
    setTimeout(() => {
      this.isCitySelected = !!citySelected;

      if (!this.isCitySelected) {
        this.sectionActive = null;
      }
    }, 100);
  }

  logout() {
    this.authenticationService.logout();
  }

  onPreviewChange() {
    this.preview = !this.preview;

    this.explorePreviewLocalStorage.setItem(this.preview);
  }

  onLocationDropdownChange() {
    const interval = setInterval(() => {
      if (this.exploreEmpty) {
        clearInterval(interval);

        this.exploreEmpty.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
      }
    }, 50);
  }

  onLocalitySelectedChange(isLocalitySelected: boolean) {
    setTimeout(() => {
      this.isLocalitySelected = isLocalitySelected;
    });
  }

  previousClick() {
    const previousSlide = this.searchResult.getPreviousSlide(this.activeSearchResultSlide);

    if (previousSlide) {
      this.setActiveSearchResultSlide(previousSlide);
    }
  }

  nextClick() {
    const nextSlide = this.searchResult.getNextSlide(this.activeSearchResultSlide);

    if (nextSlide) {
      this.setActiveSearchResultSlide(nextSlide);
    }
  }

  setActiveSearchResultSlide(searchResultSlide: SearchResultSlide) {
    if (searchResultSlide.type === SearchResultSlideType.RESTAURANT) {
      this.clickedRestaurant = searchResultSlide.restaurant;
    } else {
      this.activeTypeCategorySection = searchResultSlide.typeCategorySection;
    }

    this.activeSearchResultSlide = searchResultSlide;
    this.hasPrev = !!this.searchResult.getPreviousSlide(searchResultSlide);
    this.hasNext = !!this.searchResult.getNextSlide(searchResultSlide);
  }

  private scrollToPrevPosition() {
    const restaurantPosition = this.restaurantPositionLocalStorage.getItem();

    if (restaurantPosition) {
      DomUtils.querySelectorAsync('.search-desktop-main').then(() => {
        this.appService.scrollTop(restaurantPosition, 0);

        this.restaurantPositionLocalStorage.removeItem();
      });
    }
  }

  onFavoriteStatusChange() {
    this.favoritesService.favoriteStatusChange(this.searchParams).then((locationFavorites: LocationFavorites) => {
      this.locationFavorites.eatenCount = locationFavorites?.eatenCount;

      this.locationFavorites.favoritesCount = locationFavorites?.favoritesCount;
    });
  }

  @Auth()
  onFavoriteClick() {
    this.favoritesService.favoriteClickSubject.next(true);
  }

  openVideoModal() {
    this.modalService.open(this.videoModalRef, ModalType.FAVORITE_SQUARE);
  }

  searchInputValue(searchValue: string) {
    this.searchValueExists = searchValue !== '';
  }

  onSelectPlace(place: Place) {
    this.searchParamsService.getByPlaceId(place.placeId).subscribe(params => {
      this.searchParamsLocalStorage.setItem(params);
      this.searchParams = params;

      this.navigatorService.goToUrl(this.searchParams.path).then();
    });
  }

  findMe() {
    this.searchParamsService.getByMyGeoLocation(true).then(searchParamByGeoLocation => {
      this.goToSearchParamsPath(searchParamByGeoLocation);
    });
  }

  goToSearchParamsPath(searchParams: SearchParams) {
    this.searchParamsLocalStorage.setItem(searchParams);
    this.searchParams = searchParams;

    this.navigatorService.goToUrl(this.searchParams.path).then();
  }

  @Auth()
  goToUser() {
    this.navigatorService.goToUser();
  }

  private checkEmbedExistence() {
    this.hasWalkingEmbeds = this.locationEmbeds.some(embed => embed.tourType === TourType.WALKING);
    this.hasDiningEmbeds = this.locationEmbeds.some(embed => embed.tourType === TourType.DINING);
  }
}
