<ng-container *ngIf="isMobile">
  <div *ngIf="locationFavorites?.restaurantFavorites?.length === 0" class="d-flex flex-column">
    <div
      [appBackgroundUrl]="backgroundImage ? backgroundImage: ''"
      class="bg-height"
    ></div>

    <div
      class="position-absolute-0 bottom-footer-height backdrop-filter-brightness-0-85"
      (click)="favoriteClicked()"
    ></div>
  </div>

  <div *ngIf="locationFavorites?.restaurantFavorites?.length > 0">
    <div class="row row-favorites-mobile">
      <ng-container *ngFor="let restaurantFavorites of locationFavorites.restaurantFavorites">
        <ng-container *ngFor="let favorite of restaurantFavorites.favorites">
          <div
            *ngIf="favorite.view.isColumns"
            class="col-favorite col-4 mb-1"
            (click)="onFavoriteClick(favorite, viewType.CLASSIC, restaurantFavorites.restaurant)"
          >
            <app-phantom
              [view]="favorite.view"
              [viewType]="viewType.COLUMNS"
              [hasPaddingTop]="false"
            ></app-phantom>

            <app-div-rectangle *ngIf="favorite.status">
              <div class="card-favorite card card-item-image">
                <app-food-img
                  [images]="favorite.food.images"
                  [favoriteStatus]="favorite.food.favoriteStatus"
                  [width]="720"
                  [height]="720"
                  [foodGallery]="true"
                  [alt]="favorite.food.title"
                  [favStatus]="favorite.status"
                ></app-food-img>

                <i
                  *ngIf="favorite.status === favoriteStatus.TRY_ME"
                  class="icon-v2 red-dot scale-1_2 position-mobile-favorites"
                ></i>
              </div>
            </app-div-rectangle>
          </div>

          <div *ngIf="favorite.view.isClassic" class="col-favorite col-12">
            <app-phantom
              [view]="favorite.view"
              [viewType]="viewType.CLASSIC"
              [hasPaddingTop]="false"
            ></app-phantom>

            <div (click)="onFavoriteClick(favorite, viewType.COLUMNS, restaurantFavorites.restaurant)">
              <app-classic-food-image
                [food]="favorite.food"
                [images]="favorite.food.images"
                [favStatus]="favorite.status"
              ></app-classic-food-image>
            </div>

            <div class="d-flex justify-content-between py-3 px-4">
              <div class="classic-item-right font-size-1rem font-weight-500 flex-content-vc">
                <a appHrefNone class="text-default" (click)="onRestaurantClick()">
                  {{ restaurantFavorites.restaurant?.nameView }}
                </a>
              </div>

              <app-heart
                *ngIf="favorite.food"
                [food]="favorite.food"
                [favoriteModal]="true"
                (favoriteStatusChanged)="onFavoriteStatusChange(favorite, $event)"
                class="height-32px w-8"
              ></app-heart>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="d-flex flex-column align-items-center mt-3-5rem mb-2rem">
      <div class="h-36px w-100"></div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!isMobile">
  <div [ngClass]="{'h-96vh': locationFavorites?.restaurantFavorites.length === 0}" class="modal-body d-flex p-0">
    <div class="d-flex flex-column w-100">
      <div
        [ngClass]="{'h-100vh': locationFavorites?.restaurantFavorites.length === 0, 'h-100': locationFavorites}"
        class="d-flex justify-content-center bg-f3 w-100"
      >
        <div class="d-flex flex-column w-65rem z-index-1 h-100">
          <h3 *ngIf="locationFavorites" class="text-center my-2rem"> {{ cityName }} </h3>

          <div
            *ngIf="locationFavorites?.restaurantFavorites.length === 0"
            [appBackgroundUrl]="backgroundImage ? backgroundImage: ''"
            class="position-absolute-favorites backdrop-filter-brightness-0-85 cursor-pointer"
            (click)="favoriteClicked()"
          >
            <div class="position-fixed-0 favorites-video-gradient"></div>

            <h3 class="text-white text-center my-2rem position-absolute left-0 right-0"> {{ cityName }} </h3>

            <div class="target-line cursor-pointer z-index-3">
              <div class="row">
                <div class="col-12 text-center text-white">
                  Targets | Favorites
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex flex-column">
            <div *ngIf="locationFavorites" class="row row-favorites">
              <ng-container *ngFor="let restaurantFavorites of locationFavorites.restaurantFavorites">
                <ng-container *ngFor="let favorite of restaurantFavorites.favorites, let isLastPost = last;">
                  <div
                    *ngIf="favorite.view.isColumns"
                    [ngClass]="{'mb-4rem': !isLastPost, 'mb-5': isLastPost}"
                    class="col-favorite col-2-5"
                  >
                    <app-phantom
                      [view]="favorite.view"
                      [viewType]="viewType.COLUMNS"
                      [hasPaddingTop]="false"
                    ></app-phantom>

                    <app-div-rectangle>
                      <app-favorite-food-image
                        [food]="favorite.food"
                        [restaurant]="restaurantFavorites.restaurant"
                        (desktopRestaurantNameClick)="onDesktopRestaurantClick($event)"
                      ></app-favorite-food-image>
                    </app-div-rectangle>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
