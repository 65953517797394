<nav
  [ngClass]="{'opacity-0': isSidenavLeftOrRightMouseOver}"
  *ngIf="!isLoginModalOpen && !isSubscribeModalOpen && !isRestaurantModalOpen && !isFavoriteModalOpen"
  class="w-100 bg-white z-index-10 position-fixed top-0"
>
  <div class="row py-2 d-flex align-items-center mx-0 px-0">
    <div class="search-desktop-main flex-fill">
      <div class="search-desktop-category-section search-desktop-category-section-first">
        <div class="search-desktop-category">
          <div class="search-desktop-restaurant-header">
            <div class="flex-fill">
              <div [ngClass]="{'justify-content-end': explorePage}" class="d-flex mx-0 w-100 align-items-center">
                <a
                  appHrefNone
                  class="icon-v2 mmmm-red scale-09 mx-3 z-index-10"
                  (mouseover)="logoMouseOver = true"
                  (mouseleave)="logoMouseOver = false"
                  (click)="logoClick()"
                ></a>

                <div
                  *ngIf="explorePage"
                  class="form-control-style bg-container"
                  (mouseover)="inputHover = true"
                  (mouseleave)="inputHover = false"
                >
                  <i
                    [ngClass]="{'search-black-new': !inputHover}"
                    class="icon-v2 ml-3 scale-0-7 position-absolute top-17px"
                  ></i>

                  <app-search-city-input
                    *ngIf="inputHover"
                    [searchParams]="searchParams"
                    [inputHover]="inputHover"
                    inputClass="top-input-modal-input"
                    (inputValue)="searchInputValue($event)"
                    (selectPlace)="onSelectPlace($event)"
                    (onFindMeClick)="findMe()"
                    class="w-100"
                  ></app-search-city-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="search-desktop-sidebar">
        <div class="search-desktop-controls">
          <div class="pt-2">
            <div class="search-desktop-restaurants w-100 d-flex">
              <div class="w-60 d-flex align-items-center">
                <app-search-filters
                  *ngIf="explorePage"
                  [country]="country"
                  [searchParams]="searchParams"
                  [isHovered]="isHovered"
                  [preview]="preview"
                  [hideTemporary]="true"
                  [position]="searchFiltersPositionUnderLogo"
                  (citySelectedChange)="onCitySelected($event)"
                  (previewChange)="onPreviewChange()"
                  (locationDropdownChange)="onLocationDropdownChange()"
                  (localitySelectedChange)="onLocalitySelectedChange($event)"
                  (stateSelectedChange)="onStateChange($event)"
                  (countySelectedChange)="onCountyChange($event)"
                  class="ml-1-8rem"
                ></app-search-filters>

                <div
                  *ngIf="explorePage"
                  [ngClass]="{'opacity-0': isSidenavLeftOrRightMouseOver}"
                  class="search-desktop-filters-city ml-0-7rem"
                >
                  <app-distance-filter
                    *ngIf="searchParams?.geoLocation"
                    [searchParams]="searchParams"
                    [searchResult]="searchResult"
                  ></app-distance-filter>
                </div>
              </div>

              <div class="w-40 d-flex align-items-center justify-content-end h-37-6px">
                <a
                  *ngIf="isLoggedIn && explorePage"
                  appHrefNone
                  class="icon call cursor-pointer"
                  (click)="onFavoriteClick()"
                ></a>

                <a
                  *ngIf="explorePage"
                  appHrefNone
                  class="icon-v2 play cursor-pointer ml-2rem mb-0-35rem"
                  (click)="openVideoModal()"
                ></a>

                <a
                  *ngIf="explorePage"
                  class="icon-v2 profile-dark cursor-pointer ml-2rem"
                  (click)="goToUser()"
                ></a>

                <a
                  *ngIf="!explorePage"
                  class="icon chains-circle cursor-pointer ml-2rem"
                  (click)="openMbershipModal()"
                ></a>

                <a
                  *ngIf="!explorePage"
                  routerLink="/"
                  class="icon-v2 explore cursor-pointer ml-2rem"
                ></a>

                <a class="icon-v2 hamburger-dark cursor-pointer ml-2rem mr-2rem"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
