<div *ngIf="isProgressBarStarted" class="backdrop"></div>

<ng-container *ngIf="isLoggedIn && post">
  <app-navbar-top-post-new
    *ngIf="!isProgressBarStarted"
    [title]="currentRestaurant? currentRestaurant?.nameView : (currentUser.username)"
    [postPageType]="postPageType"
    [post]="post"
    [postRestaurantFormGroup]="postFoodFormGroup"
    (shareClick)="onShareClick()"
    (backClick)="goBack()"
  ></app-navbar-top-post-new>

  <div class="container container-post-new has-navbar-both max-width px-0 min-h-100vh">
    <div class="post-new">
      <div class="post-new-body">
        <div class="position-relative">
          <div *ngIf="!isCropperMode" class="img-preview" (click)="focusEditor()">
            <img [src]="imageData" class="w-100" alt=""/>
          </div>

          <div *ngIf="isCropperMode && post.images.length > 0" class="position-relative">
            <app-image-cropper
              [image]="post.images[0]"
              [imageData]="imageData"
              [rotation]="rotation"
              [showGrid]="showGrid"
              (imageCropped)="setCroppedImage($event)"
            ></app-image-cropper>
          </div>

          <div
            [ngClass]="{'active': needToShowKeyboard, 'post-new-caption-touch': !needToShowKeyboard}"
            class="post-new-caption p-4 text-white"
          >
            <p *ngIf="postPageType.isUser && food" class="font-weight-500 mb-2">
              {{ food.title }}
            </p>

            <form
              *ngIf="!postPageType.isUser && needToShowKeyboard"
              [formGroup]="postFoodFormGroup.form"
              class="form-food-new"
              (ngSubmit)="onSubmit()"
            >
              <div
                *ngIf="post.type.isPlate"
                class="post-new-food-input-group input-group mb-2 text-white"
              >
                <input
                  #foodTitle
                  [placeholder]="
                    food ? food.title :
                    ('Item name' +
                      (isFormSubmitted && !postFoodFormGroup.form.valid ? ' required' : '')
                    )"
                  [ngbTypeahead]="search"
                  [inputFormatter]="formatter"
                  [resultFormatter]="formatter"
                  type="text"
                  required
                  class="post-new-food-title form-control invalid-placeholder test-food-name"
                  formControlName="title"
                  (selectItem)="selectItem($event)"
                  (input)="onInput($event)"
                >

                <span class="input-group-text px-0">$</span>

                <input
                  [min]="0"
                  type="number"
                  required
                  class="post-new-food-price form-control pl-0"
                  inputmode="decimal"
                  pattern="[0-9]*"
                  formControlName="price"
                >
              </div>
            </form>

            <quill-editor
              #quill
              [(ngModel)]="post.caption"
              [placeholder]="needToShowKeyboard ? 'Caption...' : ''"
              tabindex="0"
              theme="bubble"
            >
              <div quill-editor-toolbar>
                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                </span>
              </div>
            </quill-editor>
          </div>
        </div>

        <div class="post-new-image-actions d-flex justify-content-between">
          <button
            appHrefNone
            [ngClass]="{'visibility-hidden': isInstagram}"
            class="btn btn-default btn-circle flex-content-vc-hc"
            (click)="toggleCropperMode()"
          >
            <i class="icon-v2 scale"></i>
          </button>

          <div *ngIf="postPageType.isUser" class="flex-content-vc-hc">
            <a appHrefNone class="text-dark cursor-pointer" (click)="focusEditor()">Caption</a>
          </div>

          <div *ngIf="!postPageType.isUser" class="flex-content-vc-hc flex-column w-75 px-10px">
            <app-segmented-button
              [buttons]="segments"
              [activeIndex]="1"
              (change)="onPostRestaurantTypeChange($event)"
              class="w-100"
            ></app-segmented-button>
          </div>

          <button
            appHrefNone
            [ngClass]="{'visibility-hidden': isInstagram}"
            class="btn btn-default btn-circle flex-content-vc-hc"
            (click)="rotateImage()"
          >
            <i class="icon-v2 rotate"></i>
          </button>
        </div>

        <div class="flex-content-hc">
          <div
            *ngIf="!postPageType.isUser"
            class="d-flex justify-content-center align-items-center"
            style="width: 255px"
          >
            <a
              appHrefNone
              [ngClass]="{
                'text-dark': !post.type.isPlate || needToShowKeyboard,
                'text-primary': post.type.isPlate && !needToShowKeyboard
              }"
              class="cursor-pointer w-61-5px"
              (click)="focusEditor()"
            >
              {{ needToShowKeyboard ? 'Position' : 'Caption' }}
            </a>
          </div>
        </div>

        <div class="post-new-image-actions d-flex justify-content-between">
          <div style="height: 45px; width: 45px;"></div>

          <div class="flex-content-vc-hc flex-column w-75 px-10px">
            <app-segmented-button
              *ngIf="post.type.isPlace"
              [buttons]="placeSegments"
              [activeIndex]="0"
              (change)="placeTypeChange($event)"
              class="w-100"
            ></app-segmented-button>

            <app-segmented-button
              *ngIf="post.type.isPlate"
              [buttons]="plateSegments"
              [activeIndex]=""
              (change)="plateTypeChange($event)"
              class="w-100"
            ></app-segmented-button>
          </div>

          <div style="height: 45px; width: 45px;"></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
